.active.datas {
  --tw-text-opacity: 1 !important;
  color: #8b5cf6 !important;
  color: rgb(139 92 246 / var(--tw-text-opacity)) !important;
}
.active.datas {
  background-color: #8b5cf61a !important;
}
.active.datas {
  --tw-text-opacity: 1 !important;
  color: #8b5cf6 !important;
  color: rgb(139 92 246 / var(--tw-text-opacity)) !important;
}
.w-1001 {
  width: 100%;
}
.active.datas {
  background-color: #8b5cf61a !important;
}
.justify {
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hideother:not(:first-child) {
  display: none;
}
.align-center {
  align-items: center;
}
.bg-danger-400a {
  background-color: rgb(244 63 94) !important;
  color: white !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.abspain {
  position: absolute;
  left: 20px;
  top: 13px;
  z-index: 58;
}
.flex-wraps {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  gap: 50px;
}
.fluxb li:hover {
  background-color: rgb(237 233 254);
  color: rgb(76 29 149);
}
.bolda {
  /* font-weight: bold; */
  font-size: 22px;
}
.bg-g {
  background-image: url("../../../assets/img/home-hero-gradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.react-responsive-modal-modal {
  border-radius: 20px;
  text-align: center;
}

.react-responsive-modal-modal .rounded2 {
  padding-top: 30px;
}
@media (max-width: 420px) {
  .verified-btn a,
  .verified-btn span,
  .verified-btn p {
    font-size: 11px;
  }
  .verified-btn {
    margin-inline-end: 0rem !important;
  }
}
