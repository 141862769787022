@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?422y2y');
  src:  url('fonts/icomoon.eot?422y2y#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?422y2y') format('truetype'),
    url('fonts/icomoon.woff?422y2y') format('woff'),
    url('fonts/icomoon.svg?422y2y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook-f:before {
  content: "\e933";
  color: #777e90;
}
.icon-instagram:before {
  content: "\e934";
  color: #777e90;
}
.icon-twitter:before {
  content: "\e935";
  color: #777e90;
}
.icon-youtube:before {
  content: "\e936";
  color: #777e90;
}
.icon-smartphone:before {
  content: "\e932";
  color: #3772ff;
}
.icon-star-active:before {
  content: "\e928";
  color: #fabe3c;
}
.icon-arrow:before {
  content: "\e900";
}
.icon-bit:before {
  content: "\e901";
  color: #3772ff;
}
.icon-notification:before {
  content: "\e902";
}
.icon-quote:before {
  content: "\e904";
  color: #3772ff;
}
.icon-check:before {
  content: "\e905";
  color: #3772ff;
}
.icon-avax .path1:before {
  content: "\e906";
  color: rgb(38, 41, 100);
}
.icon-avax .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(85, 90, 190);
}
.icon-avax .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-avax .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ada .path1:before {
  content: "\e90a";
  color: rgb(240, 205, 0);
}
.icon-ada .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 222, 80);
}
.icon-ada .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 234, 148);
}
.icon-ada .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 234, 148);
}
.icon-ada .path5:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(250, 163, 0);
}
.icon-ada .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 189, 0);
}
.icon-ada .path7:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(246, 142, 0);
}
.icon-ada .path8:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(246, 142, 0);
}
.icon-ada .path9:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 234, 148);
}
.icon-sol .path1:before {
  content: "\e913";
  color: rgb(38, 41, 100);
}
.icon-sol .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(85, 90, 190);
}
.icon-sol .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sol .path4:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-sol .path5:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-star:before {
  content: "\e918";
  color: #b1b5c3;
}
.icon-bnb .path1:before {
  content: "\e91a";
  color: rgb(238, 214, 113);
}
.icon-bnb .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}
.icon-bnb .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}
.icon-bnb .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}
.icon-bnb .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}
.icon-bnb .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(247, 247, 247);
}
.icon-tether .path1:before {
  content: "\e920";
  color: rgb(27, 162, 122);
}
.icon-tether .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(240, 239, 235);
}
.icon-eth .path1:before {
  content: "\e922";
  color: rgb(38, 41, 100);
}
.icon-eth .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(85, 90, 190);
}
.icon-eth .path3:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eth .path4:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-btc .path1:before {
  content: "\e926";
  color: rgb(247, 147, 26);
}
.icon-btc .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
